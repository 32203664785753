<template>
    <div class="gift_recharge_pop">
        <van-popup v-model="giftpayShow" @close="close" :lock-scroll="true" :closeOnClickOverlay='false' style="background:none">
            <div class="payPage">
                <div class="content">
                    <div class="payText">
                        <span class="title" v-html="getTitle(1)"></span>
                        <span class="payTip" v-html="getTitle(2)"></span>
                    </div>
                    </div>
                <div class="buttom">
                    <span class="btn cancel" @click="close">{{ $t('m.cancel') }}</span>
                    <span class="btn confirm" @click="build_payment">{{ $t('m.confirm') }}</span> 
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default({
    props: {
        //充值类型 1：购买礼包  2：礼包下金币充值  
        rechargeType: {
            type: Number,
            default: 1
        },
        giftpayShow: {
            type: Boolean,
            default: false
        },
        payData: {
            type: Object,
            default: {}
        },
        curreny: {            
            type: String,
            default: {}
        },
        lang: {            
            type: Number,
            default: 1
        }
    },
    data(){
        return {
            titleTexts: {
                //未购买礼包
                1: {
                    title: '',
                    tips: ''
                },
                //已购买礼包
                2: {
                    title: '',  //金币
                    tips: ''
                },
                //更换礼包
                3: {
                    title: '',  //金币
                    tips: ''
                }
            }
        }   
    },
    created(){
        ////console.log('--------------------------- 充值弹窗组件 -----------------------')
        ////console.log(this.lang, '..............lang')
        //console.log(JSON.parse(JSON.stringify(this.payData)), '.................payData')
        ////console.log(this.rechargeType, '................rechargeType')  
        
        if(this.rechargeType === 1) {
            this.titleTexts[this.rechargeType].title = this.$t('m.confirmRechargeoffer', {m: this.payData.objective, n:this.payData.valid_day})
            if(this.lang === 1 && this.payData.valid_day <= 1) {
                this.titleTexts[this.rechargeType].title = this.titleTexts[this.rechargeType].title.replace(/days/gi, "day")
            }
            this.titleTexts[this.rechargeType].tips = this.$t('m.youneedtopayoffertips', {n: this.payData[this.curreny.toLowerCase()] + ' ' + this.curreny})
        } else if(this.rechargeType === 2) {
            this.titleTexts[this.rechargeType].title = this.$t('m.confirmRechargecoins', {n: this.payData.rechargeCoinsinfo.coins})
            this.titleTexts[this.rechargeType].tips = this.$t('m.youneedtopaycoinstips', {n: this.payData.rechargeCoinsinfo[this.curreny.toLowerCase()] + ' ' + this.curreny})
        }  else if(this.rechargeType === 3) {
            this.titleTexts[this.rechargeType].title = this.$t('m.hasbuyGiftpack')
            this.titleTexts[this.rechargeType].tips = this.$t('m.hasbuyGiftpackdesc') + this.$t('m.hasbuyGiftpackdescred')
        }        
    },
    methods: {    
        getTitle(type){
            if(type === 1) {
                return this.titleTexts[this.rechargeType].title
            } else if(type === 2)
                return this.titleTexts[this.rechargeType].tips
        },    
        close() {
            this.$emit('closePop')
        },
        handlerConfirm(){
            if(this.rechargeType !== 3) {
                this.build_payment()
            } else {

            }
        },
        gotoConfimbuy(){
            this.$emit('gotoConfirmbuygift', {data: this.payData, type:1})
        },
        build_payment() {
            this.$emit('handlerBuildPayment', {data: this.payData, pay_method: this.rechargeType})
        }
    }
})
</script>